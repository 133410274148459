import {api} from "../../../../config/api";
import {HttpService} from "../../../../core/service/http.service";
import {TokenService} from "../../../../core/service/token-service";
import {store} from "../../../../shared/store";
import * as firebase from "firebase";
import {Notification} from '../../../../core/models/Message';
import moment from "moment";

export const SET_USER_DATA = 'SET_USER_DATA';
export const REMOVE_USER_DATA = 'REMOVE_USER_DATA';
export const GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS';

/**
 * Set user data in user state
 * @param user
 * @returns {function(*): *}
 */
export function setUserData(user) {
  return dispatch => dispatch({
    type: SET_USER_DATA,
    payload: {
      user: user
    }
  });
}

/**
 * Remove user data from user state
 * @returns {function(*): *}
 */
export function removeUserData() {
  TokenService.logout();
  return dispatch => dispatch({
    type: REMOVE_USER_DATA
  });
}

/**
 * Request user details
 * @returns {function(*): void}
 */
export function mySelf() {
  return dispatch => HttpService.prepareGetRequest(api.MYSELF, {})
    .execute(response => dispatch(setUserData(response['data'])));
}


export function getAdminNotifications(adminId) {
  return (dispatch) => {
    const adminNotificationsRef = firebase.database().ref('adminNotifications');

    adminNotificationsRef.child(`${adminId}/games`).on('value', (snapshot) => {
      const games = snapshot.val();
      let notifications = [];

      if (games) {
        Object.keys(games).forEach((gameId) => {
          const gameNotifications = games[gameId].map((gameData) => ({
            ...gameData,
            gameId
          }));
          // Merge notifications from all gameIds
          notifications = [...notifications, ...gameNotifications];
        });

        // Sort notifications by time
        notifications.sort((a, b) => {
          return new Date(b.time) - new Date(a.time);
        });
      }

      dispatch({
        type: GET_USER_NOTIFICATIONS,
        payload: {
          notifications
        }
      });
    });
  };
}


export function updateAdminNotification(adminId, gameId, action) {
  return () => {
    const path = `adminNotifications/${adminId}/games/${gameId}`;
    const gameRef = firebase.database().ref(path);

    gameRef.once('value').then((snapshot) => {
      const gameNotifications = snapshot.val();

      if (gameNotifications) {
        // Filter out objects with the specified action
        const updatedNotifications = gameNotifications.filter(
          (notification) => notification.action !== action
        );

        if (updatedNotifications.length > 0) {
          // Update the node with the remaining notifications
          gameRef.set(updatedNotifications)
            .then(() => {})
            .catch((err) => console.error('Error updating notifications:', err));
        } else {
          // If no notifications remain, remove the entire gameId node
          gameRef.remove()
            .then(() => {})
            .catch((err) => console.error('Error removing gameId node:', err));
        }
      }
    }).catch((err) => console.error('Error fetching notifications:', err));
  };
}


 // called to remove clicked notifications for all other associated admins for the current logged in admin.
export function updateOtherAdminsNotification(adminId, gameId, action) {
  return () => {
    const adminsPath = `adminNotifications/${adminId}/admin_ids`;
    firebase.database().ref(adminsPath).once('value').then((snapshot) => {
      const adminsId = snapshot.val();

      if (adminsId) {
        // Iterate over all admin IDs in `admin_ids`
        Object.keys(adminsId).forEach((key) => {
          const path = `adminNotifications/${key}/games/${gameId}`;
          const gameRef = firebase.database().ref(path);

          gameRef.once('value').then((snapshot) => {
            const gameNotifications = snapshot.val();

            if (gameNotifications) {
              // Filter out objects with the specified action
              const updatedNotifications = gameNotifications.filter(
                (notification) => notification.action !== action
              );

              if (updatedNotifications.length > 0) {
                // Update the node with the remaining notifications
                gameRef.set(updatedNotifications)
                  .then(() => {})
                  .catch((err) => console.error('Error updating notifications:', err));
              } else {
                // If no notifications remain, remove the entire gameId node
                gameRef.remove()
                  .then(() => {})
                  .catch((err) => console.error('Error removing gameId node:', err));
              }
            }
          }).catch((err) => console.error('Error fetching notifications:', err));
        });
      }
    }).catch((err) => console.error('Error fetching admin_ids:', err));
  };
}

// this is called to add a new remind me notification for the logged in admin
export function addRemindMeNotification(adminId, gameId, adminName = 'Admin', gameTitle = 'Game') {
  return () => {
    const path = `adminNotifications/${adminId}/games/${gameId}`;
    const newNotification = {
      action: 'gameReminder', // Action for this notification
      message: `${adminName} has requested to be reminded of ${gameTitle}`,
      seen: false,
      time: moment.utc(moment.now()).format('YYYY-MM-DD HH:mm:ss'),
      total: 1 // Default total if this is a new notification
    };

    // Check if the path exists and handle accordingly
    firebase.database().ref(path).once('value').then((snapshot) => {
      const existingNotifications = snapshot.val();

      if (existingNotifications) {
        // Check if the action already exists in the array
        const existingIndex = existingNotifications.findIndex(
          (notification) => notification.action === newNotification.action
        );

        if (existingIndex !== -1) {
          // If action exists, increment its total
          existingNotifications[existingIndex].total += 1;
        } else {
          // If action does not exist, add new notification
          existingNotifications.push(newNotification);
        }

        // Update the array in Firebase
        firebase.database().ref(path).set(existingNotifications)
          .then(() => {})
          .catch((err) => console.error('Error updating notifications:', err));
      } else {
        // If no notifications exist, create the node with the new notification
        firebase.database().ref(path).set([newNotification])
          .then(() => {})
          .catch((err) => console.error('Error creating notification node:', err));
      }
    }).catch((err) => console.error('Error fetching notifications:', err));
  };
}


// called to add the new remind me to other associated admins
export function addRemindMeNotificationToOtherAdmins(adminId, gameId, adminName = 'Admin', gameTitle = 'Game') {
  return () => {
    const adminsPath = `adminNotifications/${adminId}/admin_ids`;

    firebase.database().ref(adminsPath).once('value').then((snapshot) => {
      const adminsId = snapshot.val();
      const newNotification = {
        action: 'gameReminder', // Notification action
        message: `${adminName} has requested to be reminded of ${gameTitle}`,
        seen: false,
        time: moment.utc(moment.now()).format('YYYY-MM-DD HH:mm:ss'),
        total: 1 // Default total for a new notification
      };

      if (adminsId) {
        // Iterate over all admin IDs in `admin_ids`
        Object.keys(adminsId).forEach((key) => {
          const path = `adminNotifications/${key}/games/${gameId}`;
          const gameRef = firebase.database().ref(path);

          gameRef.once('value').then((snapshot) => {
            const existingNotifications = snapshot.val();

            if (existingNotifications) {
              // Check if the action already exists in the array
              const existingIndex = existingNotifications.findIndex(
                (notification) => notification.action === newNotification.action
              );

              if (existingIndex !== -1) {
                // If action exists, increment its total
                existingNotifications[existingIndex].total += 1;
              } else {
                // If action does not exist, add new notification
                existingNotifications.push(newNotification);
              }

              // Update the array in Firebase
              gameRef.set(existingNotifications)
                .then(() => {})
                .catch((err) => console.error(`Error updating notifications for admin ${key}:`, err));
            } else {
              // If no notifications exist, create the node with the new notification
              gameRef.set([newNotification])
                .then(() => {})
                .catch((err) => console.error(`Error creating notification node for admin ${key}:`, err));
            }
          }).catch((err) => console.error(`Error fetching notifications for admin ${key}:`, err));
        });
      }
    }).catch((err) => console.error('Error fetching admin_ids:', err));
  };
}

export function setAllNotificationsAsRead(adminId) {
  return () => {
    const path = 'adminNotifications/' + adminId + '/games';
    firebase.database().ref(path).once('value').then(snapshot => {
      const gamesNotifications = snapshot.val();
      if (gamesNotifications) {
        const updates = {};
        // Object.keys(gamesNotifications).forEach(key =>{
        //   updates[path+'/'+key] = {...gamesNotifications[key],seen: true, total: 0};
        //   updates[path+'/'+key] = {};
        // })
        updates[path] = {}
        firebase.database().ref().update(updates)
          .then(() => {})
          .catch(r => console.log('Error updating game adminNotifications.', r));
      }
    });
  };
}


