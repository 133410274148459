export const Message = {
  created_at: '',
  message: '',
  type: '',
  user_id: '',
  user_name: '',
  user_profile: '',
}

export const Notification = {
    gameId :'',
    action:'',
    message:'',
    seen: false,
    time:"",
    total: 1
}
